import React, { useCallback, useEffect, useState } from "react";
import { IProjectStatisticksItem } from "../../../models/IStatisticks";
import AnimatedNumber from "../../../components/ui/animated-number/AnimatedNumber";
import Table from "../../../components/table/Table";
import { Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { getPathWithParam } from "../../../utils/getPathWithParam";
import { ROUTES } from "../../../router/routes";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
    getProjectsStatisticks,
    setProjects,
} from "../../../store/slices/statisticks-slice";
import SearchInput from "../../../components/ui/search-input/SearchInput";
import { useInfinityScroll } from "../../../hooks/useInfinityScroll";

type ProjectsChartProps = {
    countryIds: number[];
    productIds: number[];
};

const itemsPerPage = 1000;

const ProjectsChart: React.FC<ProjectsChartProps> = ({
    countryIds,
    productIds,
}) => {
    const {
        stat: {
            projectsStatistick: { projects, hasMore },
            commonStatistick: {
                requests: { total },
            },
            projectsLoading,
        },
        app: { period },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    const [items, setItems] = useState<IProjectStatisticksItem[]>([]);
    const lastElementRef = useInfinityScroll({
        loading: projectsLoading,
        page,
        setPage,
        hasMore,
    });

    const fetchProjectsStatisticks = useCallback(() => {
        dispatch(
            getProjectsStatisticks({
                search,
                period,
                pagination: {
                    page,
                    itemsPerPage,
                },
                filter: {
                    countryIds: [0, ...countryIds],
                    productIds: [0, ...productIds],
                },
            })
        );
    }, [dispatch, period, search, page, countryIds, productIds]);

    useEffect(() => {
        fetchProjectsStatisticks();
        return () => {
            dispatch(setProjects([]));
        };
    }, [fetchProjectsStatisticks, dispatch]);

    useEffect(() => {
        setItems(projects);
    }, [projects]);

    const searchHandler = (e: any) => {
        setSearch(e.target.value);
    };

    const update = () => {
        fetchProjectsStatisticks();
    };

    return (
        <Table<IProjectStatisticksItem>
            expand={{
                getExpanded: (item) => !!item.projects,
                getEpandedRows: (item) => item.projects || [],
                expandedHeaders: [
                    {
                        name: "",
                        key: "name",
                        render: (item) => (
                            <Text fontWeight={500}>{item.name}</Text>
                        ),
                    },
                    {
                        name: "",
                        align: "center",
                        render: (item) => {
                            const value = (item.total * 100) / total;

                            return (
                                <>
                                    <AnimatedNumber number={value || 0} />%
                                </>
                            );
                        },
                    },
                    {
                        name: "",
                        key: "total",
                        align: "center",
                        animated: true,
                    },
                    {
                        name: "",
                        key: "calls",
                        align: "center",
                        animated: true,
                    },
                    {
                        name: "",
                        key: "google",
                        align: "center",
                        animated: true,
                    },
                    {
                        name: "",
                        key: "yandex",
                        align: "center",
                        animated: true,
                    },
                    {
                        name: "",
                        key: "other",
                        align: "center",
                        animated: true,
                    },
                ],
            }}
            maxH="80vh"
            toolbar={
                <SearchInput
                    placeholder="Поиск: Название, Url"
                    onChange={searchHandler}
                    value={search}
                />
            }
            refToLast={lastElementRef}
            update={update}
            isLoading={projectsLoading}
            props={{
                mt: { base: 2, sm: 4 },
            }}
            headers={[
                {
                    name: "Название",
                    key: "name",
                    type: "string",
                    props: {
                        minW: { md: "375px", sm: "240px", base: "230px" },
                    },
                    render: (item) => (
                        <>
                            <Link
                                as={RouterLink}
                                to={getPathWithParam(
                                    ROUTES.projectRequests.path,
                                    item.id
                                )}
                            >
                                {item.name}
                            </Link>
                        </>
                    ),
                    rowProps: { fontWeight: 500 },
                },
                {
                    name: "Доля",
                    align: "center",
                    render: (item) => {
                        const value = (item.total * 100) / total;

                        return (
                            <>
                                <AnimatedNumber number={value || 0} />%
                            </>
                        );
                    },
                },
                {
                    name: "Заявок",
                    key: "total",
                    align: "center",
                    animated: true,
                },
                {
                    name: "Звонки",
                    key: "calls",
                    align: "center",
                    animated: true,
                },
                {
                    name: "Google",
                    key: "google",
                    align: "center",
                    animated: true,
                },
                {
                    name: "Яндекс",
                    key: "yandex",
                    align: "center",
                    animated: true,
                },
                {
                    name: "Другие",
                    key: "other",
                    align: "center",
                    animated: true,
                },
            ]}
            rows={items}
        />
    );
};

export default ProjectsChart;
