import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    Link,
    Spinner,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import ConfirmDialog from "../../../../../components/confirmDialog/ConfirmDialog";
import {
    SUCCESS_DELETE,
    SUCCESS_GET,
    SUCCESS_PUT,
} from "../../../../../const/http-codes";
import useAppToast from "../../../../../hooks/useAppToast";
import { IProjectYandexSettings } from "../../../../../models/IProject";
import projectService from "../../../../../services/project-service";

type ConversionSettingsProps = {
    projectId: number;
    mode: "google" | "yandex";
};

const ConversionSettings: React.FC<ConversionSettingsProps> = ({
    projectId,
    mode,
}) => {
    const {
        isOpen: isConfirmOpen,
        onOpen: confirmOpen,
        onClose: confirmClose,
    } = useDisclosure();

    const modeName = useMemo(() => {
        return mode === "google" ? "Google" : "Яндекс";
    }, [mode]);

    const [loading, setLoading] = useState(false);

    const toast = useAppToast();

    const [item, setItem] = useState<IProjectYandexSettings | null>(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({
        mode: "onSubmit",
    });

    useEffect(() => {
        if (item) {
            setValue("counterId", item.counterId === -1 ? "" : item.counterId);
            setValue("callEvent", item.callEvent);
            setValue("requestEvent", item.requestEvent);
        }
    }, [item, setValue]);

    const fetchSettings = useCallback(async () => {
        setLoading(true);
        try {
            const request =
                mode === "google"
                    ? projectService.getGConversionById(projectId)
                    : projectService.getYSettingsById(projectId);

            const response = await request;

            if (response.status === SUCCESS_GET) {
                if (response.data) {
                    setItem(response.data);
                }
            }
        } catch (e) {}
        setLoading(false);
    }, [projectId, mode]);

    useEffect(() => {
        fetchSettings();
    }, [fetchSettings]);

    const createSettings = () => {
        setItem({
            id: -1,
            counterId: -1,
            callEvent: "",
            requestEvent: "",
            projectId,
        });
    };

    const onSubmit = async (data: any) => {
        const options = {
            ...item,
            ...data,
        };

        if (options.id === -1) delete options.id;

        setLoading(true);
        try {
            const request =
                mode === "google"
                    ? projectService.updateGConversion(options)
                    : projectService.updateYSettings(options);

            const response = await request;

            if (response.status === SUCCESS_PUT) {
                toast({
                    text: `Настройки ${modeName} успешно ${
                        item?.id === -1 ? "созданы" : "обновлены"
                    }`,
                });
                setItem(response.data);
            }
        } catch (e) {
            toast({
                status: "error",
                text: "Ошибка операции!",
            });
        }
        setLoading(false);
    };

    const deleteSettings = async () => {
        setLoading(true);
        try {
            const request =
                mode === "google"
                    ? projectService.deleteGConversionById(projectId)
                    : projectService.deleteYSettingsById(projectId);

            const response = await request;

            if (response.status === SUCCESS_DELETE) {
                toast({
                    text: `Настройки ${modeName} успешно удалены`,
                });
                setItem(null);
            }
        } catch (e) {
            toast({
                status: "error",
                text: "Ошибка операции!",
            });
        }
        setLoading(false);
        confirmClose();
    };

    if (loading) return <Spinner display={"block"} my={10} mx="auto" />;

    return (
        <>
            <VStack
                align={item ? "stretch" : "center"}
                pt={item ? 2 : 8}
                pb={item ? 0 : 12}
            >
                {item ? (
                    <>
                        <FormControl isInvalid={!!errors.counterId}>
                            <FormLabel>{modeName} | ID Счётчика :</FormLabel>
                            <Input
                                isDisabled={loading}
                                autoFocus
                                placeholder={`ID Счётчика`}
                                type="text"
                                {...register("counterId", {
                                    required: {
                                        value: true,
                                        message: "Обязательно для заполнения",
                                    },
                                })}
                            />
                            {errors.counterId && (
                                <FormErrorMessage>
                                    <>{errors.counterId.message}</>
                                </FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl>
                            <FormLabel>{modeName} | Событие звонка:</FormLabel>
                            <Input
                                isDisabled={loading}
                                placeholder="Событие звонка"
                                {...register("callEvent")}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{modeName} | Событие заявки:</FormLabel>
                            <Input
                                isDisabled={loading}
                                placeholder="Событие заявки"
                                {...register("requestEvent")}
                            />
                        </FormControl>
                        <HStack
                            py={2}
                            justify={
                                isNew(item.id) ? "flex-end" : "space-between"
                            }
                        >
                            {!isNew(item.id) && (
                                <Button
                                    isLoading={loading}
                                    loadingText={"Удаление..."}
                                    onClick={confirmOpen}
                                    colorScheme="red"
                                    size="sm"
                                >
                                    Удалить настройки
                                </Button>
                            )}
                            <Button
                                isLoading={loading}
                                loadingText={
                                    isNew(item.id)
                                        ? "Сохранение..."
                                        : "Обновление..."
                                }
                                onClick={handleSubmit(onSubmit)}
                                colorScheme="green"
                                size="sm"
                            >
                                {isNew(item.id) ? "Сохранить" : "Обновить"}
                            </Button>
                        </HStack>
                    </>
                ) : (
                    <>
                        <Text fontWeight={500} as="h4">
                            Настройки не созданы
                        </Text>
                        <Link
                            onClick={createSettings}
                            fontSize="16px"
                            color="blue.500"
                        >
                            Создать настройки
                        </Link>
                    </>
                )}
            </VStack>
            <ConfirmDialog
                isCentered
                onAccept={deleteSettings}
                onCancel={confirmClose}
                isOpen={isConfirmOpen}
                isLoading={loading}
                title="Удаление настроек"
                text="Вы действительно хотите удалить настройки конверсий этого проекта?"
            />
        </>
    );
};

export default ConversionSettings;

const isNew = (id: number) => id === -1;
