import {
    Box,
    Divider,
    Flex,
    Heading,
    HStack,
    Stat,
    StatGroup,
    StatHelpText,
    StatLabel,
    StatNumber,
    Text,
    TextProps,
    VStack,
} from "@chakra-ui/react";
import {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import AnimatedNumber from "../../../components/ui/animated-number/AnimatedNumber";
import { getCommonStatisticks } from "../../../store/slices/statisticks-slice";
import CountryFilter from "../../../components/filters/CountryFilter";
import ProductFilter from "../../../components/filters/ProductFilter";
import UpdateButton from "../../../components/ui/button/UpdateButton";
import ArraySwitcher from "../../../components/ui/array-switcher/ArraySwitcher";

type CommonChartProps = {
    countryIds: number[];
    productIds: number[];
    setProductIds: (ids: number[]) => void;
    setCountryIds: (ids: number[]) => void;
};

export type FieldType = {
    name: string;
    value: number;
    addon?: string;
    dots?: number;
    addonProps?: TextProps;
};

const CommonChart: React.FC<CommonChartProps> = ({
    countryIds,
    productIds,
    setCountryIds,
    setProductIds,
}) => {
    const {
        stat: { commonLoading, commonStatistick },
        app: { period },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    const [percent, setPercent] = useState(100);

    const requests: FieldType[] = useMemo(
        () => [
            {
                name: "Заявок",
                value: commonStatistick.requests.total,
            },
            {
                name: "Яндекс",
                value: commonStatistick.requests.yandex,
            },
            {
                name: "Google",
                value: commonStatistick.requests.google,
            },
            {
                name: "Звонки",
                value: commonStatistick.requests.calls,
            },
            {
                name: "Другие",
                value: commonStatistick.requests.other,
            },
        ],
        [commonStatistick]
    );

    const expenses: FieldType[] = useMemo(
        () => [
            {
                name: "Расход",
                value: commonStatistick.expenses.expense,
                addon: "$",
                addonProps: {
                    color: "green.500",
                },
                dots: 2,
            },
            {
                name: "Стоимость заявки",
                value: commonStatistick.expenses.requestCost,
                addon: "$",
                dots: 2,
                addonProps: {
                    color: "green.500",
                },
            },
        ],
        [commonStatistick]
    );

    const fetchCommonStatisticks = useCallback(() => {
        dispatch(
            getCommonStatisticks({
                period,
                filter: {
                    countryIds: [0, ...countryIds],
                    productIds: [0, ...productIds],
                },
            })
        );
    }, [period, dispatch, countryIds, productIds]);

    useEffect(() => {
        fetchCommonStatisticks();
    }, [fetchCommonStatisticks]);

    return (
        <VStack
            borderWidth={1}
            borderRadius="lg"
            p={4}
            align="flex-start"
            spacing={6}
        >
            <Flex
                w="100%"
                justifyContent={"space-between"}
                align="center"
                gap={2}
                flexWrap={{ base: "wrap", sm: "nowrap" }}
            >
                <Heading mb={{ base: 2, sm: 0 }} size="md">
                    Общая статистика
                </Heading>
                <Flex flexWrap={"wrap"} gap={2} align="center">
                    <HStack w={{ base: "100%", sm: "300px" }}>
                        <ArraySwitcher
                            koef={1}
                            max={100}
                            value={percent}
                            setValue={setPercent}
                        />
                        <Text>{percent}%</Text>
                    </HStack>
                    <ProductFilter setIds={setProductIds} />
                    <CountryFilter setCoutryIds={setCountryIds} />
                    <UpdateButton
                        onClick={fetchCommonStatisticks}
                        isLoading={commonLoading}
                    />
                </Flex>
            </Flex>
            <SectionTitle>Расход</SectionTitle>
            <Data fields={expenses} />
            <SectionTitle>Заявки</SectionTitle>
            <Data fields={requests} total={commonStatistick.requests.total} />
        </VStack>
    );
};

export default CommonChart;

const SectionTitle = ({ children }: PropsWithChildren) => {
    return (
        <Box w="100%">
            <Heading mb={1} fontWeight={600} fontSize="18px">
                {children}
            </Heading>
            <Divider />
        </Box>
    );
};

type DataProps = {
    fields: FieldType[];
    total?: number;
};

const Data: React.FC<DataProps> = ({ fields, total }) => {
    return (
        <StatGroup
            gap={4}
            flexWrap={"wrap"}
            w={"100%"}
            justifyContent="flex-start"
        >
            {fields.map((field, i) => {
                const percent = (total && (field.value * 100) / total) || 0;

                return (
                    <Stat
                        key={i}
                        maxW={{ base: "auto", sm: "25%", lg: "19%" }}
                        minW={{ base: "45%", sm: "auto" }}
                    >
                        <StatLabel lineHeight={1}>{field.name}</StatLabel>
                        <StatNumber whiteSpace={"nowrap"}>
                            <AnimatedNumber
                                fontSize={{ base: "20px", sm: "24px" }}
                                number={field.value}
                                dots={field.dots}
                                addonProps={field.addonProps}
                                addon={field.addon}
                            />
                        </StatNumber>
                        {total && (
                            <StatHelpText
                                color={
                                    percent >= 75
                                        ? "green"
                                        : percent > 25
                                        ? "orange"
                                        : "red"
                                }
                            >
                                <AnimatedNumber number={percent} />%
                            </StatHelpText>
                        )}
                    </Stat>
                );
            })}
        </StatGroup>
    );
};
