/* eslint-disable react-hooks/exhaustive-deps */
import { useConst } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/redux";
import { useSearchParams } from "../../hooks/useSearchParams";
import SelectFilter, { allId } from "./SelectFilter";

type ProductFilterProps = {
    setIds: (ids: number[]) => void;
    clearTigger?: boolean;
};

const ProductFilter: React.FC<ProductFilterProps> = ({
    setIds,
    clearTigger,
}) => {
    const { products } = useAppSelector((state) => state.product);

    const { params, setParam } = useSearchParams();

    const defaultValues = useConst(() => {
        const values = params.products;
        if (values) {
            try {
                const parsed: number[] = JSON.parse(values);
                return parsed.filter(
                    (id) =>
                        !!products.find((item) => item.id === id) ||
                        id === allId
                );
            } catch (e) {
                return products.map((item) => item.id).concat([allId]);
            }
        }
    });

    const [ids, setProductIds] = useState<number[]>([]);

    useEffect(() => {
        setParam("products", JSON.stringify(ids));
        setIds(ids);
    }, [ids]);

    return (
        <SelectFilter
            clearTrigger={clearTigger}
            filterName="Продукт"
            items={products}
            setFilter={setProductIds}
            defaultValues={defaultValues}
        />
    );
};

export default ProductFilter;
