import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createRequest } from "../../axios";
import { SUCCESS_POST } from "../../const/http-codes";
import {
    ICommonStatisticks,
    IProjectStatisticks,
    IProjectStatisticksItem,
    IStatisticks,
} from "../../models/IStatisticks";
import statisticksService, {
    getCommonStatisticksBody,
    getProjectsStatisticksBody,
} from "../../services/statisticks-service";

interface StatisticksState {
    loading: boolean;
    commonLoading: boolean;
    projectsLoading: boolean;
    statisticks: IStatisticks | null;
    commonStatistick: ICommonStatisticks;
    projectsStatistick: IProjectStatisticks;
    addedExpense: number[];
}

const initialState: StatisticksState = {
    loading: true,
    statisticks: null,
    commonStatistick: {
        requests: {
            calls: 0,
            total: 0,
            yandex: 0,
            other: 0,
            google: 0,
        },
        expenses: {
            expense: 0,
            requestCost: 0,
        },
    },
    projectsStatistick: {
        total: 0,
        hasMore: true,
        totalPages: 1,
        projects: [],
    },
    commonLoading: true,
    projectsLoading: true,
    addedExpense: [],
};

export const statisticksSlice = createSlice({
    name: "statisticks",
    initialState,
    reducers: {
        addExpense: (state, action: PayloadAction<number>) => {
            state.addedExpense.push(action.payload);
        },
        setProjects: (
            state,
            action: PayloadAction<IProjectStatisticksItem[]>
        ) => {
            state.projectsStatistick.projects = action.payload;
        },
    },
    extraReducers: ({ addCase }) => {
        // Common Get
        addCase(getCommonStatisticks.pending, (state) => {
            state.commonLoading = true;
        });
        addCase(getCommonStatisticks.fulfilled, (state, action) => {
            state.commonStatistick = action.payload;
            state.commonLoading = false;
        });
        addCase(getCommonStatisticks.rejected, (state) => {
            state.commonLoading = false;
        });
        // Projects Get
        addCase(getProjectsStatisticks.pending, (state) => {
            state.projectsLoading = true;
        });
        addCase(getProjectsStatisticks.fulfilled, (state, action) => {
            state.projectsStatistick = action.payload;
            state.projectsLoading = false;
        });
        addCase(getProjectsStatisticks.rejected, (state) => {
            state.projectsLoading = false;
        });
    },
});

export const { setProjects, addExpense } = statisticksSlice.actions;

export const statisticksReducer = statisticksSlice.reducer;

export const getCommonStatisticks = createRequest<
    ICommonStatisticks,
    getCommonStatisticksBody
>("statisticks/get-common", async (data) => {
    const response = await statisticksService.getCommon(data);

    if (response.status === SUCCESS_POST) return response.data;
});

export const getProjectsStatisticks = createRequest<
    IProjectStatisticks,
    getProjectsStatisticksBody
>("statisticks/get-projects", async (data) => {
    const response = await statisticksService.getProjects(data);

    if (response.status === SUCCESS_POST) return response.data;
});
