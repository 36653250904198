/* eslint-disable react-hooks/exhaustive-deps */
import { useConst } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/redux";
import { useSearchParams } from "../../hooks/useSearchParams";
import SelectFilter, { allId } from "./SelectFilter";

type CountryFilterProps = {
    setCoutryIds: (value: number[]) => void;
    clearTigger?: boolean;
};

const CountryFilter: React.FC<CountryFilterProps> = ({
    setCoutryIds: setIds,
    clearTigger,
}) => {
    const { countries } = useAppSelector((state) => state.country);

    const { params, setParam } = useSearchParams();

    const defaultValues = useConst(() => {
        const values = params.countries;
        if (values) {
            try {
                const parsed: number[] = JSON.parse(values);
                return parsed.filter(
                    (id) =>
                        !!countries.find((item) => item.id === id) ||
                        id === allId
                );
            } catch (e) {
                return countries.map((item) => item.id).concat([allId]);
            }
        }
    });

    const [countryIds, setCountryIds] = useState<number[]>([]);

    useEffect(() => {
        setIds(countryIds);
        setParam("countries", JSON.stringify(countryIds));
    }, [countryIds]);

    return (
        <SelectFilter
            clearTrigger={clearTigger}
            filterName="Регион"
            items={countries}
            setFilter={setCountryIds}
            defaultValues={defaultValues}
        />
    );
};

export default CountryFilter;
