import { useCallback, useMemo } from "react";
import {
    useNavigate,
    useSearchParams as routeSearchParams,
} from "react-router-dom";

type ParamsType = {
    [key: string]: string;
};

type ParamsTypeAsFunction = (prev: ParamsType) => ParamsType;

export const useSearchParams = () => {
    const [params] = routeSearchParams();
    const navigate = useNavigate();

    const objectParams = useMemo(
        () => Object.fromEntries(params.entries()),
        [params]
    );

    const setParams = useCallback(
        (newParams: ParamsType | ParamsTypeAsFunction) => {
            let params = newParams as ParamsType;
            if (typeof newParams === "function")
                params = newParams(objectParams);

            navigate(
                `${window.location.pathname}${Object.keys(params).reduce(
                    (result, key, i, arr) => {
                        if (!arr.length) return "";

                        result += `${key}=${params[key]}${
                            i !== arr.length - 1 ? "&" : ""
                        }`;

                        return result;
                    },
                    "?"
                )}`,
                { replace: true }
            );
        },
        [navigate, objectParams]
    );

    const setParam = useCallback(
        (name: string, value: string) => {
            setParams((prev) => {
                if (!value) {
                    const newParams = { ...prev };
                    delete newParams[name];
                    return newParams;
                }

                return { ...prev, [name]: value };
            });
        },
        [setParams]
    );

    return { params: objectParams, setParams, setParam };
};
