import { Text, TextProps } from "@chakra-ui/react";

const MoneyText: React.FC<{ value: string } & TextProps> = ({
    value,
    ...props
}) => {
    const small = value !== "$" && value !== "₽";

    return (
        <Text {...props}>
            {props.children || (props.children === 0 ? 0 : "--/--")}{" "}
            <Text
                fontSize={small ? ".75em" : "1em"}
                as="span"
                fontWeight={small ? 500 : 400}
                {...props}
            >
                {props.children !== undefined ? value : ""}
            </Text>
        </Text>
    );
};

export default MoneyText;
