import { useConst } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { GREEN_LIMIT, ORANGE_LIMIT, RED_LIMIT } from "../../const/colors";
import { useSearchParams } from "../../hooks/useSearchParams";
import SelectFilter, { allId, SelectFilterItem } from "./SelectFilter";

export const limits: SelectFilterItem[] = [
    {
        name: "Зеленая",
        id: 1,
        props: {
            bg: GREEN_LIMIT,
        },
    },
    {
        name: "Ораньжевая",
        id: 2,
        props: {
            bg: ORANGE_LIMIT,
        },
    },
    {
        name: "Красная",
        id: 3,
        props: {
            bg: RED_LIMIT,
        },
    },
];

type LimitFilterProps = {
    setIds: (values: number[]) => void;
    clearTrigger?: boolean;
};

const LimitFilter: React.FC<LimitFilterProps> = ({ setIds, clearTrigger }) => {
    const { params, setParam } = useSearchParams();

    const defaultValues = useConst(() => {
        const values = params.limits;
        if (values) {
            try {
                const parsed: number[] = JSON.parse(values);
                return parsed.filter(
                    (id) =>
                        !!limits.find((item) => item.id === id) || id === allId
                );
            } catch (e) {
                return limits.map((item) => item.id).concat([allId]);
            }
        }
    });

    const [limitIds, setLimitIds] = useState<number[]>(defaultValues || []);

    useEffect(() => {
        setIds(limitIds);
        setParam("limits", JSON.stringify(limitIds));
    }, [limitIds, setIds, setParam]);

    return (
        <SelectFilter
            filterName="Зона"
            items={limits}
            clearTrigger={clearTrigger}
            setFilter={setLimitIds}
            defaultValues={defaultValues}
        />
    );
};

export default LimitFilter;
