import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";

type ToastOptions = {
    title?: string;
    text?: string;
    status?: "success" | "error" | "info" | "loading" | "warning";
    duration?: number;
};

const useAppToast = () => {
    const toast = useToast();

    const showToast = useCallback(
        (options: ToastOptions) =>
            toast({
                duration: 3000,
                ...options,
                isClosable: true,
                description: options.text,
                status: options.status || "success",
            }),
        [toast]
    );

    return showToast;
};

export default useAppToast;
