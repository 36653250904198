import { useCallback } from "react";
import { getAllCountries } from "../store/slices/country-slice";
import { getAllCurrencies } from "../store/slices/currency-slice";
import { getAllProducts } from "../store/slices/product-slice";
import { useAppDispatch } from "./redux";

export const useLoginData = () => {
    const dispatch = useAppDispatch();

    const loadAppData = useCallback(async () => {
        await dispatch(getAllCurrencies());
        await dispatch(getAllCountries());
        await dispatch(getAllProducts());
    }, [dispatch]);

    return loadAppData;
};
