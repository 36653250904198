import { createSlice } from "@reduxjs/toolkit";
import { createRequest } from "../../axios";
import { SUCCESS_GET } from "../../const/http-codes";
import { IAd } from "../../models/IAd";
import adService from "../../services/ad-service";

type AdState = {
    ads: IAd[];
    loading: boolean;
};

const initialState: AdState = {
    ads: [],
    loading: false,
};

const adSlice = createSlice({
    name: "ad",
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(getAllAds.pending, (state) => {
            state.loading = true;
        });
        addCase(getAllAds.fulfilled, (state, action) => {
            state.ads = action.payload;
            state.loading = false;
        });
    },
});

export const getAllAds = createRequest<IAd[], undefined>(
    "ad/get-all",
    async () => {
        const response = await adService.getAll();

        if (response.status === SUCCESS_GET) return response.data;
    }
);

export default adSlice.reducer;
