import { Flex, FormLabel, Input, Textarea } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { getQuizCommonInfo, getQuizLoading } from "../../model/selectors";
import { ApiQuizOptions } from "firma-crm-quiz";
import { ChangeEvent } from "react";
import { quizOptionsActions } from "../../model/slice/quizOptionsSlice";

export const QuizCommonInfo = () => {
    const dispatch = useAppDispatch();
    const { title, button, formTitle } = useAppSelector(getQuizCommonInfo);
    const isLoading = useAppSelector(getQuizLoading);

    const getOnChange =
        (key: keyof Omit<ApiQuizOptions, "questions">) =>
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            dispatch(
                quizOptionsActions.changeQuizCommonInfo({
                    title,
                    button,
                    formTitle,
                    [key]: e.currentTarget.value,
                })
            );
        };

    return (
        <Flex flexDir={"column"} gap={1} mb={2}>
            <FormLabel m={0}>Заголовок квиза</FormLabel>
            <Input
                isDisabled={isLoading}
                value={title}
                onChange={getOnChange("title")}
            />
            <FormLabel m={0}>Название кнопки</FormLabel>
            <Input
                isDisabled={isLoading}
                value={button}
                onChange={getOnChange("button")}
            />
            <FormLabel m={0}>Заголовок формы</FormLabel>
            <Textarea
                isDisabled={isLoading}
                value={formTitle}
                onChange={getOnChange("formTitle")}
            />
        </Flex>
    );
};
