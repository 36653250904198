import React, { useState } from "react";
import CommonChart from "./CommonChart";
import ProjectsChart from "./ProjectsChart";

const RequestsStatisticks = () => {
    const [countryIds, setCountryIds] = useState<number[]>([]);
    const [productIds, setProductIds] = useState<number[]>([]);

    return (
        <>
            <CommonChart
                countryIds={countryIds}
                productIds={productIds}
                setCountryIds={setCountryIds}
                setProductIds={setProductIds}
            />
            <ProjectsChart countryIds={countryIds} productIds={productIds} />
        </>
    );
};

export default RequestsStatisticks;
