import { Link, Text, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "../../../components/table/Table";
import SearchInput from "../../../components/ui/search-input/SearchInput";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useInfinityScroll } from "../../../hooks/useInfinityScroll";
import { useSort, useSortOptions } from "../../../hooks/useSort";
import { IProject } from "../../../models/IProject";
import { IRequest, requestSignature } from "../../../models/IRequest";
import {
    getRequestsByProjectId,
    setRequests,
} from "../../../store/slices/request-slice";
import { ProjectProps } from "../Project";
import Statisticks from "./Statisticks";

type RequestsProps = {
    project: IProject;
    mode: ProjectProps["mode"];
};

const itemsPerPage = 15;

const defaultSortOptions: useSortOptions<IRequest> = {
    defaultKey: "createdAt",
    defaultValue: "DESC",
    signature: requestSignature,
};

const Requests: React.FC<RequestsProps> = ({ project, mode }) => {
    const {
        request: {
            requests,
            loading,
            pagination: { hasMore },
        },
        app: { period },
    } = useAppSelector((state) => state);
    const { id } = useParams();

    const { sort, setSort } = useSort<IRequest>(defaultSortOptions);

    const dispatch = useAppDispatch();
    const [items, setItems] = useState<IRequest[]>([]);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");

    const lastElementRef = useInfinityScroll({
        loading,
        page,
        setPage,
        hasMore,
    });

    const fetchRequests = useCallback(async () => {
        if (project.id.toString() !== id) return;
        await dispatch(
            getRequestsByProjectId({
                projectId: project.id,
                filters: {
                    period,
                    pagination: {
                        itemsPerPage,
                        page,
                    },
                    search,
                    sort,
                },
            })
        );
    }, [page, period, search, dispatch, id, project.id, sort]);

    useEffect(() => {
        setPage(1);
        setItems([]);
    }, [period, search, id, sort, mode]);

    useEffect(() => {
        fetchRequests();
        return () => {
            dispatch(setRequests([]));
        };
    }, [fetchRequests, dispatch]);

    useEffect(() => {
        setItems((prev) => [...prev, ...requests]);
    }, [requests]);

    const update = () => {
        setItems([]);
        setPage(1);
        fetchRequests();
    };

    return (
        <VStack>
            <Statisticks id={project.id} />
            <Table<IRequest>
                sort={{
                    onSort: setSort,
                    ...defaultSortOptions,
                }}
                refToLast={lastElementRef}
                toolbar={
                    <SearchInput
                        placeholder="Поиск: Имя, ID, Номер"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                }
                isLoading={loading}
                update={update}
                maxH="58vh"
                adaptive={true}
                headers={[
                    {
                        name: "Номер заявки",
                        key: "id",
                        render: (item) => <>#{item.id}</>,
                    },
                    {
                        name: "Номер",
                        key: "phone",
                        render: (item) => (
                            <Link href={`tel:${item.phone}`} color="blue.500">
                                {item.phone}
                            </Link>
                        ),
                    },
                    {
                        name: "Имя",
                        key: "name",
                        type: "string",
                    },
                    {
                        name: "Данные",
                        sortable: false,
                        render: (item) => (
                            <>
                                {item.data
                                    ? item.data
                                          .split("\n")
                                          .map((text, i) => (
                                              <Text key={i}>{text}</Text>
                                          ))
                                    : "--/--"}
                            </>
                        ),
                    },
                    {
                        name: "Источник",
                        key: "source",
                        type: "string",
                    },
                    {
                        name: "UTM",
                        key: "utm",
                        type: "string",
                    },
                    {
                        name: "Время",
                        key: "createdAt",
                        render: (item) => (
                            <>{format(new Date(item.createdAt), "hh : mm")}</>
                        ),
                    },
                    {
                        name: "Дата",
                        key: "createdAt",
                        render: (item) => (
                            <>
                                {format(new Date(item.createdAt), "dd.MM.yyyy")}
                            </>
                        ),
                    },
                ]}
                rows={items}
            />
        </VStack>
    );
};

export default Requests;
