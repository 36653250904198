import { IUser } from "../models/IUser";
import BaseService from "./base-service";

class UserService extends BaseService {
    async getByToken() {
        return this.api.get("/users/get-by-token");
    }
    async getAll() {
        return this.api.get("/users/");
    }
    async deleteByLogin(login: string) {
        return this.api.delete(`/users/${login}`);
    }
    async updateByLogin(dto: IUser) {
        return this.api.put("/users/", dto);
    }
    async changePassword(id: number, password: string) {
        return this.api.put("/users/change-password", { password, id });
    }
}

export default function init() {
    return new UserService();
}
