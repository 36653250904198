import { useConst } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/redux";
import { useSearchParams } from "../../hooks/useSearchParams";
import SelectFilter, { allId } from "./SelectFilter";

type CurrencyFilterProps = {
    setIds: (values: number[]) => void;
    clearTrigger?: boolean;
};

const CurrencyFilter: React.FC<CurrencyFilterProps> = ({
    setIds,
    clearTrigger,
}) => {
    const { currencies } = useAppSelector((state) => state.currency);
    const { params, setParam } = useSearchParams();

    const defaultValues = useConst(() => {
        const values = params.currency;
        if (values) {
            try {
                const parsed: number[] = JSON.parse(values);
                return parsed.filter(
                    (id) =>
                        !!currencies.find((item) => item.id === id) ||
                        id === allId
                );
            } catch (e) {
                return currencies.map((item) => item.id).concat([allId]);
            }
        }
    });

    const [ids, setStateIds] = useState<number[]>(defaultValues || []);

    useEffect(() => {
        setIds(ids);
        setParam("currency", JSON.stringify(ids));
    }, [ids, setIds, setParam]);

    return (
        <SelectFilter
            clearTrigger={clearTrigger}
            filterName="Валюта"
            items={currencies}
            setFilter={setStateIds}
            defaultValues={defaultValues}
        />
    );
};

export default CurrencyFilter;
