import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createRequest } from "../../axios";
import { SUCCESS_GET } from "../../const/http-codes";
import { IUser } from "../../models/IUser";
import UserService from "../../services/user-service";

export interface UserState {
    user: IUser | null;
}

const initialState: UserState = {
    user: null,
};

export const getUserByToken = createRequest<IUser, undefined>(
    "user/getByToken",
    async () => {
        const response = await UserService().getByToken();

        if (response.status === SUCCESS_GET) {
            return response.data;
        }
    }
);

export const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<IUser | null>) {
            state.user = action.payload;
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(getUserByToken.fulfilled, (state, action) => {
            state.user = action.payload;
        });
    },
});

export const { setUser } = UserSlice.actions;

export default UserSlice.reducer;
