export const getCurrentPeriod = () => {
    const to = new Date();

    to.setHours(23, 59, 59, 59);

    const from = new Date();

    from.setHours(0, 0, 0, 0);

    return [from, to];
};

export const getMonthPeriod = (toCurrent?: boolean) => {
    const [from, to] = getCurrentPeriod();

    from.setDate(1);

    if (!toCurrent)
        to.setDate(new Date(to.getFullYear(), to.getMonth() + 1, 0).getDate());

    return [from, to];
};
