import BaseService from "./base-service";

export type AddPhoneProps = {
    phone: string;
    regionId: number;
    isReserved: boolean;
};

export type UpdatePhoneProps = {
    phone: string;
    regionId: number;
    isReserved: boolean;
    id: number;
};

export type UpdatePhoneOptionsProps = {
    projectId: number;
    options: string | null;
};

class PhoneService extends BaseService {
    getAll(withReserved?: boolean) {
        return this.api.get(
            `/phones/${withReserved ? "?withReserved=true" : ""}`
        );
    }

    add(data: AddPhoneProps) {
        return this.api.post("/phones/", data);
    }

    clear(id: number, historyId?: number) {
        return this.api.post(`/phones/clear?id=${id}&historyId=${historyId}`);
    }

    update(data: UpdatePhoneProps) {
        return this.api.put("/phones/", data);
    }

    delete(id: number) {
        return this.api.delete(`/phones/${id}`);
    }

    updatePhoneOptions(data: UpdatePhoneOptionsProps) {
        return this.api.put("/phones/options", data);
    }
}

export default new PhoneService();
