import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
    breakpoints: {
        xs: "400px",
    },
    fonts: {
        body: `'Rubik', sans-serif;`,
    },
    colors: {
        blue: {
            50: "#E6FFFA",
            100: "#B2F5EA",
            200: "#81E6D9",
            300: "#4FD1C5",
            400: "#38B2AC",
            500: "#319795",
            600: "#2C7A7B",
            700: "#285E61",
            800: "#234E52",
            900: "#1D4044",
        },
    },
    components: {
        Button: {
            baseStyle: {
                rounded: 2,
            },
        },
        IconButton: {
            baseStyle: {
                position: "static",
            },
        },
        Tooltip: {
            baseStyle: {
                fontSize: "10px",
            },
        },
    },
});
